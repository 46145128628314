<template>
  <div class="news">
    <headerImg :src="banner" height="640" />
    <div class="title">{{$t('nav.'+$route.params.type)}}</div>
    <div class="list">
      <div v-for="(item, index) in list" :key="index">
        <a class="cover" :href="'/consultingTrends/detail/?id='" :style="`background-image: url(${item.cover})`"></a>
        <div class="content">
          <a class="text-title" :href="'/consultingTrends/detail/?id=' + item.id" :title="item.title">{{item.title}}</a>
          <div class="text-time">{{`${item.time_y}-${item.time_m}-${item.time_d}`}}</div>
          <div class="text-content" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "@/assets/images/banner3.jpg"
import headerImg from "@/components/headerImg.vue"
import news from "@/assets/hypocritical/news.js"
const reg = /<\/?.+?\/?>/g;
news.map(it => {
  it.content = it.content.replace(reg, "")
})
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  components: {headerImg},
  data(){
    return {
      banner
    }
  },
  computed: {
    list(){
      return news.filter(it => it.type == this.$route.params.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.news{
  background: #ecf1f3;
  padding-bottom: 50px;
  .title{
    color: #535353;
    line-height: 60px;
    font-size: 40px;
    padding: 20px 0;
  }
  .list{
    width: 1200px;
    margin: 0 auto;
    background: white;
    padding: 10px;
    & > div{
      display: flex;
      padding: 10px;
      transition: all .4s;
      .cover{
        min-width: 148px;
        height: 97px;
        position: relative;
        background-size: cover;
        background-position-x: center;
        cursor: pointer;
      }
      .content{
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .text-title{
          color: #535353;
          font-weight: 600;
          cursor: pointer;
        }
        .text-time{
          font-size: 14px;
          line-height: 28px;
        }
        .text-content{
          font-size: 14px;
          color: #767676;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    & > div + div{
      border-top: 1px solid #eeeeee;
    }
    & > div:hover{
      background: #eeeeee;
    }
  }
}

@media screen and (max-width: 600px) {
  .news{
    .list{
      padding: 0;
      width: 100%!important;
      & > div{
        flex-wrap: wrap;
        .cover{
          width: 100%;
          height: 195px;
        }
      }
    }
  }
}
</style>